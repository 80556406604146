import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  showModal: {
    state: false,
    type: 'form',
    error: '',
  },
};

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        showModal: {
          state: true,
          type: action.payload.type || '',
          error: action.payload.error || '',
        },
      }
      break;

    case 'CLOSE_MODAL':
      return {
        ...state,
        showModal: {
          state: false,
        },
      }
      break;

    default:
      throw new Error('Bad Action on Global Context Reducer')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={ state }>
      <GlobalDispatchContext.Provider value={ dispatch }>
        { children }
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default ({ element }) => (
  <GlobalContextProvider>
    { element }
  </GlobalContextProvider>
);

